///** ************** FRONT END QRCODE ÚNICO FUNCIONANDO ************ */

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// function App() {
//     const [qrCode, setQrCode] = useState(null);
//     const [fileReady, setFileReady] = useState(false);
//     const [authenticated, setAuthenticated] = useState(false);
//     const [loadingQrCode, setLoadingQrCode] = useState(true);

//     useEffect(() => {
//         // Fetch QR code on load
//         const fetchQrCode = async () => {
//             try {
//                 const response = await axios.get('http://localhost:3001/qrcode');
//                 setQrCode(response.data);
//                 setLoadingQrCode(false);
//             } catch (error) {
//                 console.error('Error fetching QR code:', error);
//                 setLoadingQrCode(false);
//             }
//         };

//         fetchQrCode();
//     }, []);

//     useEffect(() => {
//         // Check if contacts.txt is ready
//         const checkFileReady = async () => {
//             try {
//                 const response = await axios.get('http://localhost:3001/file-status');
//                 setFileReady(response.data.ready);
//             } catch (error) {
//                 setFileReady(false);
//             }
//         };

//         const interval = setInterval(checkFileReady, 5000); // Verifica a cada 5 segundos

//         return () => clearInterval(interval);
//     }, []);

//     useEffect(() => {
//         // Check authentication state
//         const checkAuthInfo = async () => {
//             try {
//                 const response = await axios.get('http://localhost:3001/auth-info-status');
//                 setAuthenticated(response.data.authenticated);
//             } catch (error) {
//                 console.error('Error checking authentication state:', error);
//             }
//         };

//         const interval = setInterval(checkAuthInfo, 2000); // Verifica a cada 2 segundos

//         return () => clearInterval(interval);
//     }, []);

//     return (
//         <div className="App">
//             <h1>WhatsApp QR Code</h1>
//             {loadingQrCode && <p>Loading QR code...</p>}
//             {!loadingQrCode && qrCode && !authenticated && (
//                 <div dangerouslySetInnerHTML={{ __html: qrCode }} />
//             )}
//             {authenticated && (
//                 <p>SCANEADO COM SUCESSO</p>
//             )}
//             {fileReady ? (
//                 <a href="http://localhost:3001/download" download="contacts.txt">Download Contacts</a>
//             ) : (
//                 <p>File not ready yet...</p>
//             )}
//         </div>
//     );
// }

// export default App;

//************* FIM SCRIPT ********************//




//************ SCRIPT MULTI-USUÁRIOS COM BOTÕES PARA SESSÕES ************//


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// function App() {
//     const [userId, setUserId] = useState(null); // Estado para armazenar o userId
//     const [qrCode, setQrCode] = useState(null);
//     const [fileReady, setFileReady] = useState(false);
//     const [authenticated, setAuthenticated] = useState(false);
//     const [loadingQrCode, setLoadingQrCode] = useState(false);

//     const startSession = (id) => {
//         setUserId(id);
//         setQrCode(null); // Limpar o QR Code anterior
//         setFileReady(false); // Resetar o estado de fileReady
//         setAuthenticated(false); // Resetar o estado de authenticated
//         setLoadingQrCode(true); // Iniciar o carregamento do QR Code
//     };

//     useEffect(() => {
//         if (userId !== null) {
//             // Fetch QR code on load
//             const fetchQrCode = async () => {
//                 try {
//                     const response = await axios.get(`http://localhost:3001/qrcode/${userId}`);
//                     setQrCode(response.data);
//                     setLoadingQrCode(false);
//                 } catch (error) {
//                     console.error('Error fetching QR code:', error);
//                     setLoadingQrCode(false);
//                 }
//             };

//             fetchQrCode();
//         }
//     }, [userId]);

//     useEffect(() => {
//         if (userId !== null) {
//             // Check if contacts.txt is ready
//             const checkFileReady = async () => {
//                 try {
//                     const response = await axios.get(`http://localhost:3001/file-status/${userId}`);
//                     setFileReady(response.data.ready);
//                 } catch (error) {
//                     setFileReady(false);
//                 }
//             };

//             const interval = setInterval(checkFileReady, 5000); // Verifica a cada 5 segundos

//             return () => clearInterval(interval);
//         }
//     }, [userId]);

//     useEffect(() => {
//         if (userId !== null) {
//             // Check authentication state
//             const checkAuthInfo = async () => {
//                 try {
//                     const response = await axios.get(`http://localhost:3001/auth-info-status/${userId}`);
//                     setAuthenticated(response.data.authenticated);
//                 } catch (error) {
//                     console.error('Error checking authentication state:', error);
//                 }
//             };

//             const interval = setInterval(checkAuthInfo, 2000); // Verifica a cada 2 segundos

//             return () => clearInterval(interval);
//         }
//     }, [userId]);

//     return (
//         <div className="App">
//             <h1>WhatsApp QR Code</h1>
//             <div>
//                 <button onClick={() => startSession('user1')}>Start Session 1</button>
//                 <button onClick={() => startSession('user2')}>Start Session 2</button>
//                 <button onClick={() => startSession('user3')}>Start Session 3</button>
//                 {/* Adicione mais botões conforme necessário */}
//             </div>
//             {userId && (
//                 <>
//                     {loadingQrCode && <p>Loading QR code...</p>}
//                     {!loadingQrCode && qrCode && !authenticated && (
//                         <div dangerouslySetInnerHTML={{ __html: qrCode }} />
//                     )}
//                     {authenticated && (
//                         <p>SCANEADO COM SUCESSO</p>
//                     )}
//                     {fileReady ? (
//                         <a href={`http://localhost:3001/download/${userId}`} download={`contacts_${userId}.txt`}>Download Contacts</a>
//                     ) : (
//                         <p>File not ready yet...</p>
//                     )}
//                 </>
//             )}
//         </div>
//     );
// }

// export default App;

// **************** FIM SCRIPT *************** //

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; // Importar corretamente jwtDecode

function App() {
    const [token, setToken] = useState(null);
    const [userId, setUserId] = useState(null);
    const [qrCode, setQrCode] = useState(null);
    const [fileReady, setFileReady] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const [loadingQrCode, setLoadingQrCode] = useState(false);

    const handleRegister = async (username, password) => {
        try {
            await axios.post('https://backextrair.zap77.com/register', { username, password });
            alert('User registered successfully');
        } catch (error) {
            console.error('Error registering user:', error);
            alert('Error registering user');
        }
    };

    const handleLogin = async (username, password) => {
        try {
            const response = await axios.post('https://backextrair.zap77.com/login', { username, password });
            setToken(response.data.accessToken);
            const decodedToken = jwtDecode(response.data.accessToken); // Corrigir a função de decodificação
            setUserId(decodedToken.id);
        } catch (error) {
            console.error('Error logging in:', error);
            alert('Incorrect username or password');
        }
    };

    const handleLogout = () => {
        setToken(null);
        setUserId(null);
        setQrCode(null);
        setFileReady(false);
        setAuthenticated(false);
        setLoadingQrCode(false);
    };

    const startSession = async () => {
        if (userId) {
            try {
                await axios.post('https://backextrair.zap77.com/start-session', {}, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setQrCode(null); // Limpar o QR Code anterior
                setFileReady(false); // Resetar o estado de fileReady
                setAuthenticated(false); // Resetar o estado de authenticated
                setLoadingQrCode(true); // Iniciar o carregamento do QR Code
            } catch (error) {
                console.error('Error starting session:', error);
            }
        }
    };

    const downloadContacts = async () => {
        try {
            const response = await axios.get(`https://backextrair.zap77.com/download/${userId}`, {
                headers: { Authorization: `Bearer ${token}` },
                responseType: 'blob', // Important to handle the file response correctly
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `contacts_${userId}.txt`); // Name of the file to be downloaded
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading contacts:', error);
        }
    };

    useEffect(() => {
        if (userId) {
            const fetchQrCode = async () => {
                try {
                    const response = await axios.get(`https://backextrair.zap77.com/qrcode/${userId}`, {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    setQrCode(response.data);
                    setLoadingQrCode(false);
                } catch (error) {
                    console.error('Error fetching QR code:', error);
                    setLoadingQrCode(false);
                }
            };

            fetchQrCode();
        }
    }, [userId, token]);

    useEffect(() => {
        if (userId) {
            const checkFileReady = async () => {
                try {
                    const response = await axios.get(`https://backextrair.zap77.com/file-status/${userId}`, {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    setFileReady(response.data.ready);
                } catch (error) {
                    setFileReady(false);
                }
            };

            const interval = setInterval(checkFileReady, 5000); // Verifica a cada 5 segundos

            return () => clearInterval(interval);
        }
    }, [userId, token]);

    useEffect(() => {
        if (userId) {
            const checkAuthInfo = async () => {
                try {
                    const response = await axios.get(`https://backextrair.zap77.com/auth-info-status/${userId}`, {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    setAuthenticated(response.data.authenticated);
                } catch (error) {
                    console.error('Error checking authentication state:', error);
                }
            };

            const interval = setInterval(checkAuthInfo, 2000); // Verifica a cada 2 segundos

            return () => clearInterval(interval);
        }
    }, [userId, token]);

    return (
        <div className="App">
            <h1>WhatsApp QR Code</h1>
            {!token ? (
                <div>
                    <h2>Register</h2>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        handleRegister(e.target.username.value, e.target.password.value);
                    }}>
                        <input name="username" type="text" placeholder="Username" />
                        <input name="password" type="password" placeholder="Password" />
                        <button type="submit">Register</button>
                    </form>
                    <h2>Login</h2>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        handleLogin(e.target.username.value, e.target.password.value);
                    }}>
                        <input name="username" type="text" placeholder="Username" />
                        <input name="password" type="password" placeholder="Password" />
                        <button type="submit">Login</button>
                    </form>
                </div>
            ) : (
                <div>
                    <button onClick={startSession}>Start Session</button>
                    <button onClick={handleLogout}>Logout</button>
                    {userId && (
                        <>
                            {loadingQrCode && <p>Loading QR code...</p>}
                            {!loadingQrCode && qrCode && !authenticated && (
                                <div dangerouslySetInnerHTML={{ __html: qrCode }} />
                            )}
                            {authenticated && (
                                <p>SCANEADO COM SUCESSO</p>
                            )}
                            {fileReady ? (
                                <button onClick={downloadContacts}>Download Contacts</button>
                            ) : (
                                <p>File not ready yet...</p>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
}

export default App;


